import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";
import { cn } from "ui/lib/utils";
const badgeVariants = cva("inline-flex items-center border rounded-full px-4 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-none focus:ring-0 outline-none focus:outline-none", {
  variants: {
    variant: {
      default: "bg-primary hover:bg-primary/80 border-transparent text-primary-foreground",
      secondary: "bg-secondary hover:bg-secondary/80 border-transparent text-secondary-foreground",
      destructive: "bg-destructive hover:bg-destructive/80 border-transparent text-destructive-foreground",
      outline: "text-foreground"
    }
  },
  defaultVariants: {
    variant: "default"
  }
});
export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}
function Badge({
  className,
  variant,
  ...props
}: BadgeProps) {
  return <div className={cn(badgeVariants({
    variant
  }), className)} {...props} data-sentry-component="Badge" data-sentry-source-file="badge.tsx" />;
}
export { Badge, badgeVariants };